
	import HeadingText from '@pixcap/ui-library/components/Typography/HeadingText.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import { Component, Vue, Watch } from 'vue-property-decorator';

	import { actionsWrapper as LibraryActions } from '@/store/pixcapassets/wrapper';
	import { actionsWrapper as OrganismActions } from '@/store/organism/wrapper';
	import {
		IAssetsLibraryState,
		LIBRARY_TAB,
		NAMESPACE as LIBRARY_NAMESPACE,
		SEARCH_ITEM_TYPE,
		SEARCH_ROUTES,
	} from '@/models/store/pixcapassets.interface';
	import { mapState } from 'vuex';

	@Component({
		name: 'SeoFooter',
		components: { BodyText, HeadingText },
		computed: {
			...mapState(LIBRARY_NAMESPACE, {
				librarySearch: (state: IAssetsLibraryState) => state.librarySearch,
				activeExplorerTab: (state: IAssetsLibraryState) => state.activeExplorerTab,
				activeSearchItemType: (state: IAssetsLibraryState) => state.activeSearchItemType,
			}),
		},
	})
	export default class SeoFooter extends Vue {
		librarySearch: IAssetsLibraryState['librarySearch'];
		activeExplorerTab: IAssetsLibraryState['activeExplorerTab'];
		activeSearchItemType: IAssetsLibraryState['activeSearchItemType'];

		$refs: {
			filterHighlight: HTMLInputElement;
		};

		selectedFilter: SEARCH_ITEM_TYPE | LIBRARY_TAB = this.listSortByItemType[0].value;
		isFetchingSeo = false;
		SEARCH_ITEM_TYPE = SEARCH_ITEM_TYPE;
		SEARCH_ROUTES = SEARCH_ROUTES;
		LIBRARY_TAB = LIBRARY_TAB;
		seoContent = [];

		@Watch('librarySearch')
		handleSearchChange() {
			this.fetchSeoSection();
		}

		@Watch('activeExplorerTab')
		handleChangeFilterTab() {
			this.fetchSeoSection();
			this.selectedFilter = this.activeExplorerTab as LIBRARY_TAB;
		}

		@Watch('activeSearchItemType')
		handleChangeFilterTabOnSearchPage() {
			this.fetchSeoSection();
			this.selectedFilter = this.activeSearchItemType as SEARCH_ITEM_TYPE;
		}

		@Watch('selectedFilter')
		onSelectedFilterChange() {
			this.fetchSeoSection();
			this.handleFilterHighlight();
		}

		get routerLinkTo() {
			switch (this.selectedFilter) {
				case SEARCH_ITEM_TYPE.THREE_D_CHARACTERS:
				case LIBRARY_TAB.THREE_D_CHARACTERS:
					return SEARCH_ROUTES.PACK_CHARACTERS;

				case SEARCH_ITEM_TYPE.MOCKUPS:
				case LIBRARY_TAB.MOCKUPS:
					return SEARCH_ROUTES.PACK_MOCKUPS;

				case LIBRARY_TAB.THREE_D_PACKS:
				case SEARCH_ITEM_TYPE.THREE_D_ICONS:
				case SEARCH_ITEM_TYPE.ALL_ASSETS:
				default:
					return SEARCH_ROUTES.PACK_ICONS;
			}
		}

		get title() {
			if (this.librarySearch) return this.$t('footer.searched_title');
			return this.$t('footer.title');
		}

		get shouldShowSeoContent() {
			return this.$route.name !== 'PricingPage';
		}

		get listSortByItemType() {
			return [
				{
					label: this.$t('common_elements.icons'),
					value: LIBRARY_TAB.THREE_D_PACKS,
				},
				{
					label: this.$t('common_elements.characters'),
					value: LIBRARY_TAB.THREE_D_CHARACTERS,
				},
				{
					label: this.$t('common_elements.mockups'),
					value: LIBRARY_TAB.MOCKUPS,
				},
			];
		}

		handleFilterHighlight() {
			switch (this.selectedFilter) {
				case SEARCH_ITEM_TYPE.THREE_D_CHARACTERS:
				case LIBRARY_TAB.THREE_D_CHARACTERS:
					this.$refs.filterHighlight.style.left = 'calc(100%/3)';
					break;
				case SEARCH_ITEM_TYPE.MOCKUPS:
				case LIBRARY_TAB.MOCKUPS:
					this.$refs.filterHighlight.style.left = 'calc(100% - calc(100%/3) - 4px)';
					break;
				case LIBRARY_TAB.THREE_D_PACKS:
				case SEARCH_ITEM_TYPE.THREE_D_ICONS:
				case SEARCH_ITEM_TYPE.ALL_ASSETS:
				default:
					this.$refs.filterHighlight.style.left = '4px';
					break;
			}
		}

		handleClickSeoItem(tag) {
			let type;
			switch (this.selectedFilter) {
				case SEARCH_ITEM_TYPE.THREE_D_CHARACTERS:
				case LIBRARY_TAB.THREE_D_CHARACTERS:
					type = SEARCH_ROUTES.PACK_CHARACTERS;
					break;
				case SEARCH_ITEM_TYPE.MOCKUPS:
				case LIBRARY_TAB.MOCKUPS:
					type = SEARCH_ROUTES.PACK_MOCKUPS;
					break;
				case LIBRARY_TAB.THREE_D_PACKS:
				case SEARCH_ITEM_TYPE.THREE_D_ICONS:
				case SEARCH_ITEM_TYPE.ALL_ASSETS:
				default:
					type = SEARCH_ROUTES.PACK_ICONS;
					break;
			}
			this.$router.push({
				name: 'LibraryExploreSearchList',
				params: { tab: type, searchQuery: tag },
			});
		}

		async fetchSeoSection() {
			let type;
			switch (this.selectedFilter) {
				case SEARCH_ITEM_TYPE.THREE_D_CHARACTERS:
				case LIBRARY_TAB.THREE_D_CHARACTERS:
					type = 'characters';
					break;
				case SEARCH_ITEM_TYPE.MOCKUPS:
				case LIBRARY_TAB.MOCKUPS:
					type = 'mockups';
					break;
				case LIBRARY_TAB.THREE_D_PACKS:
				case SEARCH_ITEM_TYPE.THREE_D_ICONS:
				case SEARCH_ITEM_TYPE.ALL_ASSETS:
				default:
					type = 'icons';
					break;
			}
			this.isFetchingSeo = true;
			if (this.librarySearch) {
				this.seoContent = await LibraryActions.fetchRelatedKeywords(this.$store, { keyword: this.librarySearch, type });
			} else this.seoContent = await OrganismActions.getFooterSeo(this.$store, { type });
			this.isFetchingSeo = false;
		}

		async fetch() {
			await this.fetchSeoSection();
		}
	}
