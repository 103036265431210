var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"seo-section"},[_c('HeadingText',{staticClass:"title text-center text-color-neutral-9"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_vm._v(" "),_c('div',{staticClass:"seo-content"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"flex align-center header__filter"},[_vm._l((_vm.listSortByItemType),function(option,index){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedFilter),expression:"selectedFilter"}],key:`ip-${index}`,staticClass:"custom-radio-input",class:{
							'custom-radio-input--checked':
								_vm.selectedFilter === _vm.SEARCH_ITEM_TYPE.ALL_ASSETS || _vm.selectedFilter === _vm.SEARCH_ITEM_TYPE.THREE_D_ICONS
									? option.value === _vm.LIBRARY_TAB.THREE_D_PACKS
									: _vm.selectedFilter === option.value,
						},attrs:{"id":`switch-${option.value}`,"type":"radio"},domProps:{"value":option.value,"checked":_vm._q(_vm.selectedFilter,option.value)},on:{"change":function($event){_vm.selectedFilter=option.value}}}),_vm._v(" "),_c('label',{key:`label-${index}`,class:{
							'text-weight-600':
								_vm.selectedFilter === _vm.SEARCH_ITEM_TYPE.ALL_ASSETS || _vm.selectedFilter === _vm.SEARCH_ITEM_TYPE.THREE_D_ICONS
									? option.value === _vm.LIBRARY_TAB.THREE_D_PACKS
									: _vm.selectedFilter === option.value,
						},attrs:{"for":`switch-${option.value}`}},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(option.label)+"\n\t\t\t\t\t")])]}),_vm._v(" "),_c('span',{ref:"filterHighlight",staticClass:"filter-highlight"})],2)]),_vm._v(" "),_c('div',{staticClass:"content"},[(_vm.isFetchingSeo)?_vm._l((5),function(i){return _c('div',{key:`seo-col-loading-${i}`,staticClass:"loading-section"},[_vm._m(0,true)])}):_vm._l((_vm.seoContent?.slice(0, 30)),function(item,index){return _c('router-link',{key:`seo-item-${index}`,staticClass:"seo-item cursor-pointer",attrs:{"tag":"a","to":{
						name: 'LibraryExploreSearchList',
						params: { tab: _vm.routerLinkTo, searchQuery: item },
					},"event":""},nativeOn:{"click":function($event){return _vm.handleClickSeoItem(item)}}},[_c('BodyText',{staticClass:"text-color-neutral-9",attrs:{"size":"small"}},[_vm._v(_vm._s(item))])],1)})],2)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"list--loading"},[_c('div',{staticClass:"loading-state-animation"}),_vm._v(" "),_c('div',{staticClass:"loading-state-animation"}),_vm._v(" "),_c('div',{staticClass:"loading-state-animation"}),_vm._v(" "),_c('div',{staticClass:"loading-state-animation"}),_vm._v(" "),_c('div',{staticClass:"loading-state-animation"}),_vm._v(" "),_c('div',{staticClass:"loading-state-animation"})])
}]

export { render, staticRenderFns }